import { roomStatusEnumeration } from 'server/type-file';
import { TrpcBase } from '../../../utils/trpc';
import { MathRound2 } from '../util/MathRound2';
import { bigConstruct } from '../util/bigConstruct';

export default function () {
	const { data: configure } = TrpcBase.apartment.getRooms.useQuery();

	const roomConfigure = (configure ?? []).filter((i) => i.enabled === 1);

	const roomConfigureItem = [
		{ label: '物业数量', value: roomConfigure.length },
		{
			label: '其他数量',
			value: roomConfigure.filter((i) => i.roomType !== 'room').length,
		},
		{
			label: '房间总数',
			value: roomConfigure.filter((i) => i.roomType === 'room').length,
		},
		{
			label: '已租赁房间',
			value: roomConfigure.filter(
				(i) =>
					i.roomType === 'room' && i.roomStatus === roomStatusEnumeration.busy,
			).length,
		},
		{
			label: '租赁率',
			value: MathRound2(
				bigConstruct(
					roomConfigure.filter(
						(i) =>
							i.roomType === 'room' &&
							i.roomStatus === roomStatusEnumeration.busy,
					).length / roomConfigure.filter((i) => i.roomType === 'room').length,
				).toNumber() * 100,
			),
		},

		{
			label: '空置房间数量',
			value: roomConfigure.filter(
				(i) =>
					i.roomType === 'room' && i.roomStatus === roomStatusEnumeration.open,
			).length,
		},

		{
			label: '空置房间',
			value: roomConfigure
				.filter(
					(i) =>
						i.roomType === 'room' &&
						i.roomStatus === roomStatusEnumeration.open,
				)
				.map((i) => i.roomNumber)
				.join(', '),
		},
	];

	return {
		roomConfigureItem,
		roomConfigure,
		configure,
	};
}
